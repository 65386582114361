import { defineStore } from 'pinia'

interface Application {
	name: string
	description: string
	description_short: string
	href: string
	active: boolean
}

interface CallToAction {
	name: string
	href: string
}

const description =
	'Description goes here Description goes here Description goes here Description goes here Description goes here Description goes here'
const description_short = 'Coming soon Coming soon Coming soon Coming soon 12'

export const useApplicationsStore = defineStore('applicationsStore', {
	state: (): {
		applications: Application[]
		callsToAction: CallToAction[]
	} => ({
		applications: [
			{
				name: 'Directors',
				description:
					'Direct a show that will be performed by some of the ensemble members of IMPRO Amsterdam 2025. The show will be put on the main stage of the festival.',
				description_short: 'Direct a show by the ensemble on the main stage.',
				href: '/applications/directing',
				active: true,
			},
			{
				name: 'Established groups',
				description:
					'Perform with a professional group on the main stage of the festival. Apply with one of your established shows that has been produced widely before.',
				description_short: 'Perform with a professional established show.',
				href: '/applications/groups',
				active: true,
			},
			{
				name: 'New formats',
				description:
					'Perform a new show: either because you are a new group, a collective of improvisers or simply as an established group working on a new show (including solo shows).',
				description_short:
					'Perform a new show as a new or established group (including solo shows).',
				href: '/applications/new_formats',
				active: true,
			},
			{
				name: 'Hosting a Jam',
				description:
					'Host an open stage for the visitors: apply with a specific jam idea—genre, style, concept—to guide participants into having a great time.',
				description_short: 'Host an open stage for visitors of the festival.',
				href: '/applications/jams',
				active: true,
			},
			{
				name: 'Teaching',
				description:
					'Teach local and international visitors of the festival: offer topics that you are passionate and excited to share with the world.',
				description_short:
					'Teach local and international visitors of the festival.',
				href: '/applications/teaching',
				active: true,
			},
			{
				description:
					'Currently all 50 audition spots are taken and we have a longer wait list. We have therefore closed the signup form. In the highly unlikely event that more people drop out than are on the wait list, we will open the signup again and inform you about this on our social media channels.',
				name: 'Auditioning',
				description_short: 'Application is closed',
				href: '',
				active: false,
			},
			{
				name: 'Volunteers',
				description,
				description_short,
				href: '#',
				active: false,
			},
		].filter((app) => app.active),
		callsToAction: [
			{ name: 'Have questions? Send us a message', href: '/contact/' },
		],
	}),
})
